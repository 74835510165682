
.markgained{
    color: #08bd80;
    font-family: AvertaStd, -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-weight: 700;
    margin: 5px;
}
.marklost{
    color: #eb5757;
    font-family: AvertaStd, -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-weight: 700;
    margin-left: 14%;
    margin-right: 6%;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
}
.markunattempted{
    color: #F19731;
    font-family: AvertaStd, -apple-system, BlinkMacSystemFont, sans-serif !important;
    font-weight: 700;
    margin: 5px;
}
.totalscore{
    font-size: 29px;
    line-height: 150%;
    font-weight: bold;
    color: #3C4852;
}
.keepitup{
    font-size: .875rem!important;
    font-weight: 400!important;
    line-height: 1.375rem!important;
}
.result_card .card{
    width: 100%;
}


@media (min-width: 300px) {
    .card-design {
        width: 100%;
    }
}
@media (min-width: 700px) {

}
@media screen and (min-width: 900px) {
    .card-design {
        width: 50%;
    }
}
@media screen and (min-width: 1300px) {

}
