* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #333;
}

.container {
  color: #000;
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  max-width: 941px;
}

#footer .privacy-policy {
  text-align: center;
}
.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}
li.page-item {
  width: 3rem;
  padding-right: 3.1rem;
}
span.nav-link-text {
  text-align: right;
  display: inline-block;
}

.nav-icon {
  display: inline-block;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.hovertext:active {
  pointer-events: none;
}

.container > p {
  font-size: 32px;
}

.row .container .question {
  width: 100%;
  right: 0;
}

.options {
  position: relative;
  padding-left: 40px;
}

#options label {
  display: block;
  font-size: 14px;
  cursor: pointer;
}
.q-opt-wrap .card-body {
  transition: 0.1s ease;
}
.q-opt-wrap .card-body:hover {
  box-shadow: 4px 4px 10px 0px rgb(0, 0, 0, 0.3);
}

.options input {
  opacity: 0;
  position: absolute;
}
.options input:checked ~ .checkmark {
  /* When the checkbox is checked, add a blue background */
  background-color: #198754;
}

.answered-table span {
  display: inline-block;
  padding: 5px;
  width: 35px;
  height: 40px;
  margin: 6px;
  line-height: 34px;
  text-align: center;
  border-radius: 5px;
}
.practice-test-qno .answered-table span {
  cursor: pointer;
  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  opacity: 0.5;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 5px;
  width: 35px;
  height: 40px;
  margin: 6px;
  line-height: 34px;
  text-align: center;
}
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #a3a3a3;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.options input:checked ~ .checkmark:after {
  display: block;
}

.subchapters-data div.col-xl-3.col-sm-6.col-12.mb-4 {
  padding: 2px;
  display: inline-block;
}

.card-header {
  background-color: #ffffff;
}

.options .checkmark:after {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  transition: 300ms ease-in-out 0s;
}

.options input[type="radio"]:checked ~ .checkmark {
  transition: 300ms ease-in-out 0s;
}
.final-test-page .options input[type="radio"]:checked ~ .checkmark {
  background: #6cc4ed;
}

.options input[type="radio"]:checked ~ .checkmark:after {
  transform: translate(-50%, -50%) scale(1);
}

.btn-primary {
  background-color: #555;
  color: #ddd;
  border: 1px solid #ddd;
}

.btn-primary:hover {
  background-color: #21bf73;
  border: 1px solid #21bf73;
}

.btn-success {
  padding: 5px 25px;
  background-color: #21bf73;
}

@media (max-width: 576px) {
  .question {
    width: 100%;
    word-spacing: 2px;
  }
}

h5.card-text,
h5.mb-0.text-center {
  color: black;
}
.practice-test h5.mb-0.text-center {
  color: black;
  font-family: Karla !important;
  font-size: 17px !important;
}

.solution-text {
  border-radius: 10px 10px 10px 10px;
  background-color: #fefefe;
  width: 90%;
  font-family: Manrope;
}
.test_solution_view {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 24px;
  padding-bottom: 0;
}
.question_view_result {
  font-family: Manrope;
  font-weight: 200;
  font-size: 18px;
}
.view_answer_box {
  border-radius: 14px 12px 12px 12px;
  background-color: #f9ecff;
  box-shadow: 5px 10px #a277b6;
  margin-bottom: 1rem !important;
}
.mock-test.row {
  padding-left: 33%;
}

body {
  background-color: #fbfbfb;
}

a.list-group-item.list-group-item-action.py-2.ripple {
  color: #ffffff;
}

a.list-group-item.list-group-item-action.py-2.ripple {
  background-color: #282c34;
}

.list-group-item.active {
  border-color: gold;
}

#biologyData {
  text-decoration: none;
}

div#navbarNavAltMarkup {
  align-items: center;
  left: 16%;
  position: relative;
  /* float: left; */
  display: inline-block;
}

span.arrowMenu {
  position: relative;
  left: 7px;
}

.subjects-name {
  position: relative;
  left: 20%;
}

.question-no {
  position: relative;
  right: 42%;
}

.question {
  position: relative;
}

.form-inline.subjects-end-button {
  position: relative;
  right: 3%;
}

.box {
  background-color: #fbfbfb;
  /*border-radius: 10px;*/
  overflow: hidden;
  height: 30px;
  width: 50px;
  /*max-height: 35vh;*/
  /*-webkit-box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);*/
  /*box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);*/
  margin: 2px;
  border-style: solid;
  border-color: #282c34;
}

.grid {
  min-height: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
}

div#options {
  max-width: 90%;
}

.get-quote button.btn.btn-outline-success.practice-test {
  position: relative;
  bottom: 8px;
}

.grid > div {
  flex-basis: calc(7% - 100px);
}

.hot-card h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*@media (min-width: 991.98px) {*/
/*    main {*/
/*        padding-left: 240px;*/
/*    }*/
/*}*/

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.chapter-info {
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}

.chapter-item {
  padding: 45px;
  padding-left: 55px;
  padding-bottom: 0;
  display: block;
  position: relative;
  cursor: pointer;
}

.chapter-item:before {
  content: "";
  border-radius: 10px;
  transition: 0.7s ease;
  left: -20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  transform: scale(0.8);
  opacity: 0;
}

.chapter-item:hover:before {
  opacity: 1;
  transform: scale(1);
}

.goto-arrow {
  position: absolute;
  right: 10px;
  top: -12%;
}
.goto-arrow_Test {
  position: absolute;
  right: 10%;
  top: -12%;
}

svg.lock-icon {
  position: relative;
  right: 6%;
}

.sub-chapter svg.lock-icon {
  position: absolute;
  right: 12%;
  top: 2%;
}

.sub-chapter .goto-arrow {
  position: absolute;
  right: 5%;
  top: 35%;
}

.sub-chapter .chapter-info {
  border-bottom: 0;
}
.sub-chapter-item {
  padding-bottom: 0;
  padding-top: 10px;
  display: block;
  position: relative;
  cursor: pointer;
}

.sub-chapter-item:before {
  content: "";
  border-radius: 10px;
  transition: 0.7s ease;
  left: -20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  transform: scale(0.8);
  opacity: 0;
}

.sub-chapter-item:hover:before {
  opacity: 1;
  transform: scale(1);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
}

.loading {
  border: 2px solid #ccc;
  width: 60px;
  height: 60px;
  position: relative;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-top-color: #1ecd97;
  border-left-color: #1ecd97;
  animation: spin 1s infinite ease-in;
}

.chapter-no {
  font-size: 1.7rem;
  line-height: 1;
  font-weight: 600;
  position: absolute;
  left: 0;
}

.bio .chapter-no,
.txt-bio {
  color: var(--bio);
}
.che .chapter-no,
.txt-che {
  color: var(--che);
}
.phy .chapter-no,
.txt-phy {
  color: var(--phy);
}
.test .chapter-no {
  color: darkorange;
}
.bg-bio {
  background-color: var(--bio);
}
.bg-che {
  background-color: var(--che);
}
.bg-phy {
  background-color: var(--phy);
}
.bg-test {
  background-color: var(--test);
}
.bg-prev {
  background-color: var(--prev);
}
.hot-card {
  transition: 0.5s ease;
}
.hot-card .card-body-icon {
  transform: scale(0.8);
  transition: 0.5s ease;
}
.hot-card:hover .card-body-icon {
  transform: scale(1);
}

.hot-card:hover {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.answered-table {
  position: initial;
}

.answered-table.active {
  transform: translateX(0);
}

.ans-success {
  border: 2px solid #74db26;
}

.ans-wrong {
  border: 2px solid #fe0000;
}

.view_Result .exam_name {
  text-align: center;
}
.view_Result .exam_name2 {
  font-family: emoji;
  color: #471962;
  font-size: 26px;
}

.practice-test-qno #questions-list .question_no {
  display: inline-block;
}

@media (min-width: 300px) {
  .lock_Style {
    font-size: 22px !important;
    float: right;
    color: red;
    margin-right: 10%;
    top: 32%;
    right: -4%;
    position: absolute;
  }
}

@media screen and (min-width: 900px) {
  .lock_Style {
    font-size: 22px !important;
    float: right;
    color: red;
    margin-right: 10%;
    top: -9%;
    right: -4%;
    position: absolute;
  }
}

@media (min-width: 300px) {
  .lock_Style_Test {
    font-size: 22px !important;
    float: right;
    color: red;
    margin-right: 10%;
    top: 27%;
    right: 16%;
    position: absolute;
  }
  .goto-arrow_Test {
    position: absolute;
    right: 14%;
    top: 23%;
  }
}

@media screen and (min-width: 900px) {
  .lock_Style_Test {
    font-size: 22px !important;
    float: right;
    color: red;
    margin-right: 10%;
    top: 23%;
    right: 7%;
    position: absolute;
  }
}
