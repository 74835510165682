
.container {
    /*border-bottom: #fff 2px ridge;*/
}

.pagination {
    margin-top: 31px;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #47ccde;
    color: #fff;
}
.view_Result .pagination>.active>a {
    background-color: #9f1496;
    color: #fff;
    border: 1px solid #47de53;
}

.pagination>li>a {
    border: 1px solid #47de53;
    outline: none;
    cursor: pointer;
    margin-right: 42px;
    width: 122px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    font-weight: bold;
}
.test.mb-4 {
    width: 98%;
}
.update-btn.col-8.mb-3 {
    text-align: center;
    width: 100%;
}
.view_Result .pagination>li>a {
    border: 1px solid #a277b6;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    width: 48px;
    font-size: 13px;
    height: 31px;
    border-radius: 18px;
    font-weight: bold;
}

.pagination>li>a, .pagination>li>span {
    color: white;
    background-color: #74db26;
}

.view_Result  .pagination>li>a, .pagination>li>span {
    color: white;
    background-color: #a277b6;
}

.view_Result .page-link:hover {
    z-index: 2;
    color: black;
}
.answer{
    border: 0px solid #24357a;
    border-radius: 20px;
    margin-left: 83%;
    margin-right: -24%;
    overflow: auto;
    height: 491px;
    width: 55%;
}

.final-test .question{
    margin-right: 0%;
    margin-left: -17%;
    overflow: auto;
    position: absolute;
    width: 59%;
    height: 57%;
}
.answered-table span:hover {
    background-color: #31516c !important;
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #908f92b3;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #90adffcc; }

.answer-section{
    margin-top: 0%;
}
.q-opt-wrap .card.mb-3:hover {
    border: 2px solid #6cc4ed;
}
.not-attempted{
    height: 23px;
    width: 31px;
    background-color: #727676;
    border-color: #727676;
    float:left;
}
.attempted{
    height: 23px;
    width: 31px;
    background-color: green;
    border-color: green;
    float:left;
}
.question-box{
    text-align: center;
    font-family: 'FontAwesome';
    color: #7b42ff;
    font-weight: 700;
}
.practice-test .questionBox{
    overflow: auto;
    height: 434px;
    font-family: Karla !important;
    font-size: 17px !important;
}.practice-test .option{
     font-family: Karla;
     font-size: 18px;
 }
.navbarsize{
    background: #262626;

}
.questionBox{
    overflow: auto;
}
.answered-table .box{
    border: 1px solid #ddd;
}
.end-button{
    float: right;
    margin-right: -38%;
}

.final-test{
    padding: 0px;
}

.practice-test .answered-table {
    display: none;
}



@media (min-width: 300px) {
    .practice-test {
        margin-top: 20%;
        width: 100%;
    }
    .final-test-page .practice-test {
        margin-top: 13%;
        width: 100%;
    }
    .practice-test .spanAnswer{
        position: absolute;
        width: 36%;
        font-size: 18px;
        text-align: center;
        color: green;
        margin-left: 53%;
        background-color: white;
        margin-top: -10%;
    }
    .practice-test .checkansButtonDiv {
        position: absolute;
        padding-left: 49%;
        padding-top: 10%;
    }
    .practice-test-qno .endButton{
        margin-top: 1%;
        margin-left: 33%;
        width: 131px;
        --bs-btn-color: #cdc1c2;
        --bs-btn-border-color: #7d7c7c;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #dc3545;
        --bs-btn-hover-border-color: #dc3545;
        --bs-btn-focus-shadow-rgb: 220,53,69;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #dc3545;
        --bs-btn-active-border-color: #dc3545;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #dc3545;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #dc3545;
        --bs-gradient: none;
    }
}
@media (min-width: 700px) {
    .practice-test {
        margin-top: 11%;
        width: 100%;
        margin-left: 2%;
    }
    .practice-test .spanAnswer{
        position: absolute;
        width: 26%;
        font-size: 23px;
        text-align: center;
        color: green;
        margin-left: 67%;
        background-color: white;
        margin-top: -5%;
    }
    .practice-test .checkansButtonDiv {
        position: absolute;
        padding-left: 48%;
        padding-top: 4%;
    }
    .practice-test-qno .endButton{
        margin-top: 2%;
        margin-left: 40%;
        width: 131px;
        --bs-btn-color: #cdc1c2;
        --bs-btn-border-color: #7d7c7c;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #dc3545;
        --bs-btn-hover-border-color: #dc3545;
        --bs-btn-focus-shadow-rgb: 220,53,69;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #dc3545;
        --bs-btn-active-border-color: #dc3545;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #dc3545;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #dc3545;
        --bs-gradient: none;
    }
}
@media screen and (min-width: 900px) {
    .practice-test {
        margin-top: 10%;
        width: 80%;
        margin-left: 28%;
    }
    .navbarsize{
        margin-top: 66px;
    }
}
@media screen and (min-width: 1300px) {
    .practice-test {
        margin-top: 10%;
        width: 100%;
        margin-left: 10%;
    }
    .final-test-page .practice-test {
        margin-top: 3%;
        width: 100%;
        margin-left: 10%;
    }
    .navbarsize{
        margin-top: 66px;
    }
    .practice-test .spanAnswer{
        position: absolute;
        width: 12%;
        font-size: 16px;
        color: green;
        margin-left: 80%;
        background-color: white;
        margin-top: -4%;
        text-align: center;
    }
    .practice-test .checkansButtonDiv {
        position: absolute;
        padding-left: 29%;
        padding-top: 2%;
    }
    .practice-test-qno .endButton{
        margin-top: 11%;
        margin-left: 47%;
        width: 131px;
        background-color: #ff05059c;
        --bs-btn-color: #cdc1c2;
        --bs-btn-border-color: #7d7c7c;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #dc3545;
        --bs-btn-hover-border-color: #dc3545;
        --bs-btn-focus-shadow-rgb: 220,53,69;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #dc3545;
        --bs-btn-active-border-color: #dc3545;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #dc3545;
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #dc3545;
        --bs-gradient: none;
    }
}
@media screen and (max-width: 600px) {

    .practice-test-qno .logo {
        padding-left: 40%;
        visibility: hidden;
    }
}
.practice-test-qno .logo {
    padding-left: 40%;
}
.practice-test-qno .boxColor{
    background-color: #74db26;
    opacity: .7 !important;
}

.practice-test-qno .heading{
    height: 65px;
    background: hsla(0,0%,100%,.05);
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0;
    border-bottom: outset;
    text-align: center;
}

.practice-test .checkansButton {
    border: 1px solid #4e9abd;
    outline: none;
    cursor: pointer;
    margin-right: 42px;
    width: 122px;
    height: 40px;
    border-radius: 20px;
    color: white;
    background-color: #6cc4ed;
    text-align: center;
    font-weight: bold;
}
.practice-test .answerBox{
    border-radius: 10px 10px 0 0;
    background-color: #f6ffec;
    width: 90%;
    font-family: georgia,times-new-roman,serif;
}

.answerBoxSolution{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
    line-height: 24px;
    padding-bottom: 0;
}



@media (min-width: 300px) {

}
@media (min-width: 700px) {

}
@media screen and (min-width: 900px) {

}
@media screen and (min-width: 1300px) {

}
