:root {
    --che: #b808b7b0;
    --bio: #46a43dd6;
    --phy: #ec2020cc;
    --test:#0972c5a1;
    --prev:#ec8420f5;
}

.hot-card {
    --bs-card-border-color: rgb(201, 200, 200);
    --bs-card-border-radius: 1rem;
}

.table {
    --bs-table-striped-bg: rgba(0, 0, 0, 0.03);
}

html {
    position: relative;
    min-height: 100%;
}

a {
    color: #142031;
    text-decoration: none;
}

a:hover {
    color: #142031;
}

.body {
    overflow-x: hidden;
}

h5, h6 {
    font-weight: 600;
}

.small, small {
    font-size: .8em;
}

.form-control:focus {
    box-shadow: none;
    outline: 0 none;
}

/*.body.sticky-footer {*/
/*    margin-bottom: 56px;*/
/*}*/

.body.sticky-footer .content-wrapper {
    min-height: calc(100vh - 56px - 56px);
}

/*.body.fixed-nav {*/
/*    padding-top: 56px;*/
/*}*/

.content-wrapper {
    min-height: 100vh;
    padding-top: 1rem;
}

#custom-breadcrumb {
    margin-top: 70px;
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    list-style: none;
    font-size: .9rem;
}

.scroll-to-top {
    position: fixed;
    right: 15px;
    bottom: 3px;
    display: none;
    width: 50px;
    height: 50px;
    text-align: center;
    color: white;
    background: rgba(52, 58, 64, 0.5);
    line-height: 45px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
    color: white;
}

.scroll-to-top:hover {
    background: #343a40;
}

.scroll-to-top i {
    font-weight: 800;
}

.smaller {
    font-size: 0.7rem;
}

.o-hidden {
    overflow: hidden !important;
}

.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

nav#mainNav {
    background: #fff;
    border-bottom: 1px solid #ddd;
}

#mainNav .navbar-collapse {
    overflow: auto;
    max-height: 75vh;
}

#mainNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    cursor: pointer;
    color: #000;
    padding-right: 1.5rem;
}

.navbar-nav .fa {
    padding-right: 10px;
    width: 25px;
}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    float: right;
    content: '\f107';
    font-family: 'FontAwesome';
}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse.collapsed:after {
    content: '\f105';
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level {
    padding-left: 0;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a {
    display: block;
    padding: 0.5em 0;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a:hover,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a:hover {
    text-decoration: none;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a {
    padding-left: 1em;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a {
    padding-left: 2em;
}

#mainNav .navbar-collapse .sidenav-toggler {
    display: none;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link {
    position: relative;
    min-width: 45px;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    float: right;
    width: auto;
    content: '\f105';
    border: none;
    font-family: 'FontAwesome';
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link .indicator {
    position: absolute;
    top: 5px;
    left: 21px;
    font-size: 10px;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown.show>.nav-link:after {
    content: '\f107';
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown .dropdown-menu>.dropdown-item>.dropdown-message {
    overflow: hidden;
    max-width: none;
    text-overflow: ellipsis;
}

@media (min-width: 992px) {
    #mainNav .navbar-brand {
        width: 250px;
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
    }

    #mainNav .navbar-collapse {
        overflow: visible;
        max-height: none;
    }

    #mainNav .navbar-collapse .navbar-sidenav {
        position: absolute;
        top: 0px;
        left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 56px;
    }

    .practice-test-qno #mainNav .navbar-collapse .navbar-sidenav {
        position: absolute;
        top: 0px;
        left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 0px;
        height: calc(100vh);
    }

    ul.navbar-nav.ml-auto {
        margin-left: auto !important;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item {
        width: 250px;
        padding: 0 15px;
        overflow: auto;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
        padding: 1em 1.5em;
        transition: .5s ease;
        border-radius: 2rem;
    }

    .navbar-sidenav>.nav-item>.nav-link:hover {
        background: rgb(89 187 233 / 30%);
    }
    .navbar-sidenav>.nav-item>.nav-link i {
        transition: .6s ease;
    }
    .navbar-sidenav>.nav-item>.nav-link:hover i {
        color: #0dcaf0;
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
        transform: scale(1.1);
        will-change: transform;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        padding-left: 0;
        list-style: none;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li {
        width: 250px;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
        padding: 1em;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a {
        padding-left: 2.75em;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
        padding-left: 3.75em;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link {
        min-width: 0;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
        width: 24px;
        text-align: center;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown .dropdown-menu>.dropdown-item>.dropdown-message {
        max-width: 300px;
    }
}

#mainNav.fixed-top .sidenav-toggler {
    display: none;
}

@media (min-width: 992px) {
    #mainNav.fixed-top .navbar-sidenav {
        height: calc(100vh - 58px);
    }

    #mainNav.fixed-top .sidenav-toggler {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: calc(100vh - 56px);
    }

    #mainNav.fixed-top .sidenav-toggler>.nav-item {
        width: 275px;
        padding: 0;
    }

    #mainNav.fixed-top .sidenav-toggler>.nav-item>.nav-link {
        padding: 1em;
    }
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler {
    background-color: #212529;
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler a i {
    color: #adb5bd;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler {
    background-color: #dee2e6;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler a i {
    color: rgba(0, 0, 0, 0.5);
}

.body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler {
    overflow-x: hidden;
    width: 4%;
}

.body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-item,
.body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-link {
    width: 55px !important;
}

.body.sidenav-toggled #mainNav.fixed-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
}

#mainNav.static-top .sidenav-toggler {
    display: none;
}

@media (min-width: 992px) {
    #mainNav.static-top .sidenav-toggler {
        display: flex;
    }
}

.body.sidenav-toggled #mainNav.static-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
}

.content-wrapper {
    overflow-x: hidden;
    background: #fff;
}

@media (min-width: 992px) {
    .content-wrapper {
        margin-left: 250px;
        border-left: 1px solid #ddd;
        padding-left: 15px;
    }
}

#sidenavToggler i {
    font-weight: 800;
}

.navbar-sidenav-tooltip.show {
    display: none;
}

@media (min-width: 992px) {
    .body.sidenav-toggled .content-wrapper {
        margin-left: 55px;
    }
}

.body.sidenav-toggled .navbar-sidenav {
    width: 4%;
}

/*.body.sidenav-toggled .navbar-sidenav .nav-link-text {*/
/*    display: none;*/
/*}*/

.body.sidenav-toggled .navbar-sidenav .nav-item,
.body.sidenav-toggled .navbar-sidenav .nav-link {
    width: 55px !important;
}

.body.sidenav-toggled .navbar-sidenav .nav-item:after,
.body.sidenav-toggled .navbar-sidenav .nav-link:after {
    display: none;
}

.body.sidenav-toggled .navbar-sidenav .nav-item {
    white-space: nowrap;
}

.body.sidenav-toggled .navbar-sidenav-tooltip.show {
    display: flex;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    color: #868e96;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
    color: #868e96;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link:hover {
    color: #adb5bd;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
    color: #868e96;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:hover,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:hover {
    color: #adb5bd;
}

#mainNav.navbar-dark .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    color: #adb5bd;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

@media (min-width: 992px) {
    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav {
        background: #343a40;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a {
        color: white !important;
        background-color: #495057;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:focus,
    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:hover {
        color: white;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        background: #343a40;
    }
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
    color: rgb(0 0 0);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item>.nav-link:hover {
    color: rgb(0 0 0);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
    color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:hover,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:hover {
    color: rgba(0, 0, 0, 0.7);
}

#mainNav.navbar-light .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
    #mainNav.navbar-light .navbar-collapse .navbar-sidenav {
        background: #f8f9fa;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a {
        color: #000 !important;
        background-color: #e9ecef;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:focus,
    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:hover {
        color: #000;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        background: #f8f9fa;
    }
}

.hot-card {
    text-align: center;
    height: 100%;
}

.hot-card .card-footer {
    border-top-style: dashed;
}

.card-body-icon {
    font-size: 4rem;
    text-align: center;
    z-index: 0;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 1;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 2;
    }
    .body.sidenav-toggled .navbar-sidenav .nav-link-text {
        display: none;
    }
}

.card-login {
    max-width: 25rem;
}

.card-register {
    max-width: 40rem;
}

footer.sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 56px;
    background-color: #e9ecef;
    line-height: 55px;
}

@media (min-width: 992px) {
    footer.sticky-footer {
        width: calc(100% - 250px);
    }
}

@media (min-width: 992px) {
    .body.sidenav-toggled footer.sticky-footer {
        width: calc(100% - 55px);
    }

    .sidenav-toggled+.content-wrapper {
        margin-left: 50px;
    }
}

.navbar-sidenav {
    text-align: left;
    font-weight: 500;
}

.App {
    text-align: left;
    ;
}

.chapter-inner {
    transition: .5s ease;
}
.chapter-inner:hover {
    transform: scale(1.02);
    border-color: #2a2a2a !important;
}
.subj-header {
    border-bottom: 1px solid #565454;
}
.subj-header i {
    /*background: rgba(85, 187, 234, .1);*/
    font-size: 2.5rem;
    padding: 6px;
    border-radius: 10px;
}

.subj-nav-icon {
    display: inline-block;
    position: relative;
    top: 5px;
    right: 10px;
}

.subj-header .sub-name {
    display: inline-block;
}
.question .test > span {
    display: inline !important;
}
mjx-container[jax="CHTML"][display="true"] {
    display: block;
    text-align: left !important;
}

.q-no-bg {
    background-color: #ddd;
    padding: 5px 10px;
    /* padding-left: 7px; */
    border-radius: 5px;
    text-align: center;
    font-size: .9rem;
}

@media (max-width: 992px) {
nav#mainNav {
    padding: 10px 15px;
}
#custom-breadcrumb {
    display: none;
}
.content-wrapper {
    padding-top: 4rem
}
.chapter-item {
    padding-right: 0;
}
.chapter-item:before {
    content: none;
}
.chapter-item {
    padding: 15px 10px 15px 35px;
}
.chapter-no {
    font-size: 1.3rem;
}
.h5, h5 {
    font-size: calc(.7rem + .9vw);
}
.chapter-info {
    padding-bottom: 15px;
}
.navbar-toggler:focus {
    border: 0;
    outline: 0 none;
    box-shadow: none;
}
.h6, h6 {
    font-size: .9rem;
}
.small, small {
    font-size: .7em;
}
.chapter-inner-item h6 {
    margin-bottom: 2px;
}
.goto-arrow {
    right: 5px;
    top: 50%;
    margin-top: -20px;
}
.chapter-info {
    position: initial;
}
.show-counter .countdown-link {
    flex-wrap: wrap;
}
.countdown-link strong.text-danger {
    display: block;
    min-width: 100%;
    margin-bottom: 10px;
}
}


@media (max-width: 480px) {
    .chapter-no {
        font-size: 1.2rem;
    }
    .chapter-info h5 {
        margin-bottom: 0;
    }

}
