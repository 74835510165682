.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 73%);
}

.popup-container .popup_head{
    font-family: serif;
    font-size: 34px;
    margin-top: 15%;
}
.popup-container .close {
    float: inline-end;
    font-size: 32px;
    position: relative;
    right: 9px;
    bottom: 10px;
}
.popup-container .box {
    border-style: none;
}

.popup-container .container {
    max-width: 1200px;
}

.popup-body {
    overflow-y: scroll;
    box-sizing: content-box;
}

.repeater-cls span {
    margin-left: 5px;
}

@media screen and (min-width: 900px) {
    .popup-container .popup-body {
        position: absolute;
        /* left: 30%; */
        /* right: 30%; */
        /* bottom: 30%; */
        width: 75%;
        height: 78%;
        /* top: 30%; */
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

}
/**
* Template Name: FlexStart
* Updated: Sep 18 2023 with Bootstrap v5.3.2
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
}

a {
    color: #4154f1;
    text-decoration: none;
}

a:hover {
    color: #717ff5;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
    padding: 0;
    overflow: visible;
}

.repeater-cls {
    display: inline-flex;
    justify-content: center;
}

.section-header {
    text-align: center;
    padding-bottom: 40px;
}

.section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
}

.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
}

@media (max-width: 768px) {
    .section-header p {
        font-size: 28px;
        line-height: 32px;
    }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
    padding: 40px 20px;
    background: #fff;
    width: 90%;
    height: 100%;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}
.popup-container .col-lg-3 {
    width: 33%;
}
.pricing .box:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.pricing h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}

.pricing .price {
    font-size: 36px;
    color: #444444;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.pricing .price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}

.pricing .price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}

.pricing img {
    padding: 30px 40px;
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
}

.pricing ul li {
    padding-bottom: 10px;
}

.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}

.pricing .btn-buy {
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: #4154f1;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid #4154f1;
}

.pricing .btn-buy:hover {
    background: #4154f1;
    color: #fff;
}

.pricing .featured {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #4154f1;
    color: #fff;
}
@media (min-width: 300px) {
    .card-registration .popup-container .popup-body {
        width: -webkit-fill-available;
        height: min-content;
        overflow: hidden;
        top: 26%;
    }
    .popup-container .popup-body {
        position: absolute;
        bottom: 5%;
        top: 15%;
        text-align: center;
        margin: 5px;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .row.gy-3.aos-init.aos-animate {
        text-align: center;
        position: relative;
        left: 15px;
    }
    .popup-container .col-lg-3 {
        width: 100%;
    }
    .popup-container .box {
        position: relative;
        left: 10px;
    }
    .section-header p {
        font-size: 25px;
        line-height: 32px;
    }
}


@media (min-width: 700px) {
    .card-registration .popup-container .popup-body {
        overflow: hidden;
        width: max-content;
        height: fit-content;
    }
    .popup-container .popup-body {
        position: absolute;
        left: 0%;
        right: 0%;
        bottom: 30%;
        top: 30%;
        text-align: center;
        margin: auto;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .popup-container .col-lg-3 {
        width: 33.33%;
    }
}
