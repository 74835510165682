.gradient-custom {
    /* fallback for old browsers */
    background: #f093fb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(rgb(10, 185, 207), rgb(139, 61, 255));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(rgb(10, 185, 207), rgb(139, 61, 255))
}


.card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
}
.card-registration .select-arrow {
    top: 13px;
}

.error-show {
  color: rgb(255, 81, 81);
  animation: shake 0.3s linear;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-15px); }
  50% { transform: translateX(15px); }
  75% { transform: translateX(-15px); }
  100% { transform: translateX(0); }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
